import React from "react";
import {
  BGContainer,
  BGImage,
  BGImageShade,
  Circle,
  HomePageStyle,
  MainContainer,
  MenuContainer,
  MenuItem,
  MenuItemRotated,
  MenuItemText,
  MenuLine,
  OverflowContainer,
  OverflowItem,
  SubTitle,
  Title
} from "./style";
import Helmet from "react-helmet";
import {Stack, useMediaQuery} from "@mui/material";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {navigate} from "gatsby";

export function Homepage() {
  const desktop = useMediaQuery('(min-width:769px)');

  return (
      <>
        <Helmet htmlAttributes={{lang: "fr"}}>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"/>
        </Helmet>
        <Circle className={"circle-one"}/>
        <Circle  className={"circle-two"}/>
        <HomePageStyle>
          <MenuContainer>
            <MenuItem className={"overflow-menu-one"}>
              <MenuLine/>
              <MenuItemText minWidth={100}>
                <AniLink fade to="/le-mariage/" style={{ textDecoration: 'none', color:"#917D78FF" }}>
                  Le Mariage
                </AniLink>
              </MenuItemText>
              <MenuLine/>
            </MenuItem>
            <MenuItem className={"overflow-menu-two"} onClick={() => navigate("/galerie")}><MenuItemRotated rotation={-90}><MenuLine/><MenuItemText>Galerie photo</MenuItemText><MenuLine/></MenuItemRotated></MenuItem>
            <MenuItem className={"overflow-menu-three"}>
              {desktop ?
                  <MenuItemRotated rotation={90}>
                    <MenuLine/>
                    <MenuItemText>
                      <AniLink fade to="/notre-lune-de-miel/" style={{ textDecoration: 'none', color:"#917D78FF" }}>
                        Notre lune de miel
                      </AniLink>
                    </MenuItemText>
                    <MenuLine/>
                  </MenuItemRotated> :
                  <>
                    <MenuLine/>
                    <MenuItemText>
                      <AniLink fade to="/notre-lune-de-miel/" style={{ textDecoration: 'none', color:"#917D78FF" }}>
                        Notre lune de miel
                      </AniLink>
                    </MenuItemText>
                    <MenuLine/>
                  </>
              }
            </MenuItem>
            <OverflowContainer>
              <MainContainer>
                <BGContainer>
                  <BGImage  className={"background-image-blur"}/>
                  <BGImage/>
                  <BGImageShade/>
                  <Title>
                    <Stack direction={"row"}>
                      <div className={"title-name title-stephanie"}><span>Stéphanie</span></div>
                      <div className={"title-and"}><span>&</span></div>
                      <div className={"title-name title-eric"}><span>Eric</span></div>
                    </Stack>
                  </Title>
                  <SubTitle>To be continued ...</SubTitle>
                </BGContainer>
                <OverflowItem className={"overflow-item-one"}/>
                <OverflowItem className={"overflow-item-two"}/>
              </MainContainer>
            </OverflowContainer>
          </MenuContainer>
        </HomePageStyle>
      </>
  );
}


